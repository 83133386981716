const endpoints = {
  auth: {
    login: "/auth/login",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    changePassword: "/auth/change-password",
    logout: "auth/logout",
  },
  dashboard: {
    getAdmin: "/admin/administrator/get-administrators",
    createAdmin: "/admin/administrator/create-administrator",
    getRestaurant: "/admin/restaurant/get-restaurants",
    getAllRestaurant: "/admin/restaurant/get-all-restaurants",
    mergeRestaurant: "/admin/restaurant/merge-restaurants",
    createRestaurant: "/admin/restaurant/create-restaurant",
    removeAccessRestaurant: (restaurant_id) =>
      `/admin/restaurant/change-status/${restaurant_id}`,
    getRestaurantDetails: "/restaurant/get-my-restaurant-details",
    createRestaurantLocation: "/restaurant/create-restaurant-location",
    updateRestaurantLocation: (addressId) =>
      `/restaurant/update-restaurant-location/${addressId}`,
    updateRestaurantProfile: "/restaurant/update-restaurant",
    getMenuItems: "/food/get-foods",
    getAdminMenuItem: (restaurantId) => `/admin/food/get-foods/${restaurantId}`,
    createFoodCategory: "/food-category/create-food-category",
    createAdminFoodCategory: (restaurantId) =>
      `/admin/food-category/create-food-category/${restaurantId}`,
    updateFoodCategory: (categoryId) =>
      `/food-category/update-food-category/${categoryId}`,
    updateAdminFoodCategory: (food_category_id) =>
      `/admin/food-category/update-food-category/${food_category_id}`,
    createFoodItem: "/food/create-food",
    updateFoodItem: (foodItemId) => `/food/update-food/${foodItemId}`,
    createAdminFoodItem: "/admin/food/create-food",
    updateAdminFoodItem: (foodId) => `/admin/food/update-food/${foodId}`,
    getFoodCategoryById: (id) => `/food-category/get-food-category/${id}`,
    getAdminFoodCategoryById: (food_category_id) =>
      `/admin/food-category/get-food-category/${food_category_id}`,
    getFoodItemById: (id) => `/food/get-food/${id}`,
    getAdminFoodItemById: (food_id) => `/admin/food/get-food/${food_id}`,
    getFoodCategories: "/food-category/get-food-categories",
    getAdminFoodCategory: (restaurantId) =>
      `/admin/food-category/get-food-categories/${restaurantId}`,
    getMeData: "/auth/me",
    getFoodCategoryForRestaurant:
      "/restaurant-category/get-restaurant-categories",
    getDeal: "/deal/get-deals",
    getDealAdmin: (restaurantId) => `/admin/deal/get-deals/${restaurantId}`,
    createDeal: "/deal/create-deal",
    createAdminDeal: () => `/admin/deal/create-deal`,
    updateDeal: (restaurantDealId) => `/deal/update-deal/${restaurantDealId}`,
    updateAdminDeal: (restaurantDealId) =>
      `/admin/deal/update-deal/${restaurantDealId}`,
    getDealById: (id) => `/deal/get-deal/${id}`,
    getAdminDealById: (dealId) => `/admin/deal/get-deal/${dealId}`,
    deactivateDeal: (dealId) => `/deal/change-deal-status/${dealId}`,
    deactivateAdminDeal: (dealId) => `/admin/deal/change-deal-status/${dealId}`,
    getRestaurantLocationOwnerDetail: (restaurant_id) =>
      `/admin/restaurant/get-restaurant-details/${restaurant_id}`,
    createRestaurantLocationOwner:
      "/admin/restaurant/create-restaurant-location",
    updateRestaurantLocationOwner: (addressId) =>
      `/admin/restaurant/update-restaurant-location/${addressId}`,
    updateRestaurantLocationProfile: "/admin/restaurant/update-restaurant",
    deleteAdmin: (administratorId) =>
      `/admin/administrator/delete-administrator/${administratorId}`,
    deleteMenuItem: (foodItemId) => `/food/delete-food/${foodItemId}`,
    deleteAdminMenuItem: (foodId) => `/admin/food/delete-food/${foodId}`,
    deleteDeal: (restaurantDealId) => `/deal/remove-deal/${restaurantDealId}`,
    deleteAdminDeal: (restaurantDealId) =>
      `/admin/deal/remove-deal/${restaurantDealId}`,
    deleteRestaurantLocation: (restaurantId) =>
      `/restaurant/delete-restaurant-location/${restaurantId}`,
    deleteAdminRestaurantLocation: (restaurantId, addressId) =>
      `/admin/restaurant/delete-restaurant-location/${restaurantId}/${addressId}`,
    deleteRestaurantProfile: "/auth/delete-account",
    deleteRestaurantImages: (restaurantImageId) =>
      `/restaurant/delete-restaurant-image/${restaurantImageId}`,
    deleteRestaurantLocationImages: (restaurantId, restaurantImageId) =>
      `/admin/restaurant/delete-restaurant-image/${restaurantId}/${restaurantImageId}`,
    getAdminFoodTagCategory: "/admin/food/get-food-tag-categories",
    getFoodTagCategory: "/food/get-food-tag-categories",
    uploadFoodItemsFile: "/admin/food/import-foods-from-csv",
    uploadRestaurantFile: "/admin/restaurant/import-restaurants-from-csv",
    getRestaurantFoodCategoryForAdmin:
      "/admin/restaurant-category/get-restaurant-categories",
    getRestaurantCategoryForAdminById: (restaurant_category_id) =>
      `/admin/restaurant-category/get-restaurant-category/${restaurant_category_id}`,
    createRestaurantCategoryforAdmin:
      "/admin/restaurant-category/create-restaurant-category",
    editRestaurantCategoryforAdmin: (restaurant_category_id) =>
      `/admin/restaurant-category/update-restaurant-category/${restaurant_category_id}`,
    deleteRestaurantCategoryForAdmin: (restaurant_category_id) =>
      `/admin/restaurant-category/delete-restaurant-category/${restaurant_category_id}`,
    getfoodItems: "/admin/food-report/get-food-reports",
    deletefoodItems: (food_id) => `/admin/food-report/remove-food/${food_id}`,
    ignorefoodItems: (food_id) => `/admin/food-report/ignore-food/${food_id}`,
    getRestaurantAllAddressForAdmin: (restaurantId) =>
      `/admin/restaurant/get-restaurant-addresses/${restaurantId}`,
    getRestaurantAllAddressForRestaurant:
      "/restaurant/get-my-restaurant-addresses",
  },
};

export default endpoints;
