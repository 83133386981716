import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDeleteRequest, fetchGetRequest, fetchMultipartPatchRequest, fetchMultipartPostRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { deleteModalHide, deleteRestaurantProfileModalHide } from "./modals";

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  isDeletePopupLoading: false,
  restaurantDetails: null,
};

export const getRestaurantDetail = createAsyncThunk(
  "restaurantDetail/getRestaurantDetail",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getRestaurantDetails,
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message);

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createRestaurantLocation = createAsyncThunk(
  "restaurantDetail/createRestaurantLocation",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchMultipartPostRequest(
        endpoints.dashboard.createRestaurantLocation,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to create Restaurant Location");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateRestaurantLocation = createAsyncThunk(
  "restaurantDetail/updateRestaurantLocation",
  async ({payload, addressId}, thunkAPI) => {
    try {
      const response = await fetchMultipartPatchRequest(
        endpoints.dashboard.updateRestaurantLocation(addressId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to update Restaurant Location");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateRestaurantProfile = createAsyncThunk(
  "restaurantDetail/updateRestaurantProfile",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchMultipartPatchRequest(
        endpoints.dashboard.updateRestaurantProfile,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to update Restaurant Profile");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const deleteRestaurantLocation = createAsyncThunk(
  'restaurantDetail/deleteRestaurantLocation',
  async (restaurantId, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteRestaurantLocation(restaurantId),
      );
      toast.success(response?.data?.message || "Restaurant Location deleted Successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      toast.error(err?.data?.message || "Failed to delete Restaurant Location");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deleteRestaurantProfile = createAsyncThunk(
  'restaurantDetail/deleteRestaurantProfile',
  async (payload, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteRestaurantProfile,
      );
      thunkAPI.dispatch(deleteRestaurantProfileModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteRestaurantProfileModalHide());
      console.log('restaurant profile', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Restaurant");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deleteRestaurantImages = createAsyncThunk(
  'restaurantDetail/deleteRestaurantImages',
  async (restaurantImageId, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteRestaurantImages(restaurantImageId),
      );
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      console.log('restaurant images', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Restaurant Images");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const { reducer, actions } = createSlice({
  name: "restaurantDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getRestaurantDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRestaurantDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restaurantDetails = action.payload.restaurant;
      })
      .addCase(getRestaurantDetail.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createRestaurantLocation.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createRestaurantLocation.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createRestaurantLocation.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(updateRestaurantLocation.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateRestaurantLocation.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateRestaurantLocation.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(updateRestaurantProfile.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateRestaurantProfile.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateRestaurantProfile.rejected, (state) => {
        state.isPopupLoading = false;
      })
      
      .addCase(deleteRestaurantLocation.pending, state => {
        state.isDeletePopupLoading = true;
      })
      .addCase(deleteRestaurantLocation.fulfilled, (state, action) => {
        state.isDeletePopupLoading = false;
        
      })
      .addCase(deleteRestaurantLocation.rejected, state => {
        state.isDeletePopupLoading = false;
      })
      
      .addCase(deleteRestaurantProfile.pending, state => {
        state.isDeletePopupLoading = true;
      })
      .addCase(deleteRestaurantProfile.fulfilled, (state, action) => {
        state.isDeletePopupLoading = false;
        
      })
      .addCase(deleteRestaurantProfile.rejected, state => {
        state.isDeletePopupLoading = false;
      }),
});

export default reducer;
export const {} = actions;
