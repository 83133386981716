import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Loading from "../../../components/loading";
import { useDispatch } from "react-redux";
import { constant } from "../../../constants";
import { createRestaurantCategoryforAdmin } from "../../../../store/reducers/restaurantCategory";
import "./restaurant-category.scss";

const CreateRestaurantCategory = ({
  closePopup,
  restaurantCategoryData,
  getRestaurantCategory,
}) => {
  const dispatch = useDispatch();

  const handleCreateRestaurantCategory = async (values) => {
    const payload = {
      restaurantCategoryName: values.restaurantCategoryName.trim(),
      description: values.description.trim(),
    };

    dispatch(createRestaurantCategoryforAdmin(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          closePopup();
          getRestaurantCategory();
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Add Restaurant Category</h2>
        <i onClick={closePopup} className="bx bx-x"></i>
        <Formik
          initialValues={{
            restaurantCategoryName: "",
            description: "",
          }}
          validationSchema={Yup.object().shape({
            restaurantCategoryName: Yup.string()
              .required("Restaurant Category Name is required")
              .max(50, "Restaurant Category Name cannot exceed 50 characters"),
          })}
          onSubmit={(values, { setSubmitting }) =>
            handleCreateRestaurantCategory(values, setSubmitting)
          }
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="restaurantCategoryName">
                        Restaurant Category Name
                      </label>
                      <Field
                        type="text"
                        name="restaurantCategoryName"
                        id="restaurantCategoryName"
                        placeholder="Restaurant Category Name"
                        component={Input}
                      />
                    </div>
                  </div>
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <Field
                        type="text"
                        name="description"
                        id="description"
                        placeholder="Description"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className="btn"
                >
                  {restaurantCategoryData?.isPopupLoading && (
                    <Loading></Loading>
                  )}
                  {isSubmitting ? "Submitting..." : "Create"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default CreateRestaurantCategory;
