import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../layouts/dashboard-layout";
import AddDealPopup from "./addDealPopup";
import { deactivateDeal, getDeal } from "../../../../store/reducers/deal";
import {
  selectDealData,
  selectRestaurantDetailsData,
  selectRestaurantLocationData,
} from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import Loading from "../../../components/loading";
import EditDealPopup from "./editDealPopup";
import "./deal.scss";
import {
  selectIsAddDealModalOpen,
  selectIsEditDealModalOpen,
} from "../../../../store/selectors/modalSelectors";
import {
  addDealModalHide,
  addDealModalOpen,
  editDealModalHide,
  editDealModalOpen,
} from "../../../../store/reducers/modals";
import toast from "react-hot-toast";
import { getRestaurantAllAddress } from "../../../../store/reducers/restaurantAddress";

const Deal = () => {
  const [restaurantDealId, setRestaurantDealId] = useState(null);
  const dealData = useSelector(selectDealData);
  const isAddDealPopupOpen = useSelector(selectIsAddDealModalOpen);
  const isEditDealPopupOpen = useSelector(selectIsEditDealModalOpen);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPagesize] = useState(999999);
  const [expandedDeals, setExpandedDeals] = useState({});
  const { restaurantDetails } = useSelector(selectRestaurantDetailsData);
  const { restaurantId } = restaurantDetails.restaurant;
  const [addresses, setAddresses] = useState([]);

  const toggleShowAllItems = (dealId) => {
    setExpandedDeals((prevExpanded) => ({
      ...prevExpanded,
      [dealId]: !prevExpanded[dealId],
    }));
  };

  const renderFoodDeals = (foodDeals) => {
    const maxToShow = 3;
    const remainingCount = foodDeals.length - maxToShow;
    const isExpanded = expandedDeals[foodDeals];

    return (
      <>
        {foodDeals.slice(0, maxToShow).map((foodDeal, index) => (
          <React.Fragment key={foodDeal?.foodId}>
            {index > 0 && ", "}
            {foodDeal?.foodItemName}
          </React.Fragment>
        ))}
        {remainingCount > 0 && !isExpanded && (
          <>
            <span
              className="moreLink"
              onClick={() => toggleShowAllItems(foodDeals)}
            >
              {" "}
              + {remainingCount} more
            </span>
          </>
        )}
        {isExpanded && (
          <>
            {foodDeals.slice(maxToShow).map((foodDeal, index) => (
              <React.Fragment key={foodDeal?.foodId}>
                {", "}
                {foodDeal?.foodItemName}
              </React.Fragment>
            ))}
            <span
              className="moreLinkLess"
              onClick={() => toggleShowAllItems(foodDeals)}
            >
              {" "}
              - Show Less
            </span>
          </>
        )}
      </>
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeal({ pageNum, pageSize })).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
    dispatch(addDealModalHide());
    dispatch(editDealModalHide());
    return () => {
      dispatch(addDealModalHide());
      dispatch(editDealModalHide());
    };
  }, [dispatch, pageNum, pageSize]);

  useEffect(() => {
    dispatch(getRestaurantAllAddress()).then((response) => {
      if (response?.meta?.requestStatus === constant.thunkStatus.FULFILLED) {
        setAddresses(response?.payload?.addresses ?? []);
      }
    });
  }, [restaurantId]);
  const handleDeactivateDeal = async (dealId, isActive) => {
    const payload = {
      isActive: isActive ? false : true,
    };

    dispatch(deactivateDeal({ payload, dealId })).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message ||
              (isActive
                ? "Deal deactivated successfully"
                : "Deal activated successfully")
          );
          dispatch(
            getDeal({
              pageNum: pageNum,
              pageSize: pageSize,
            })
          );
          return;

        default:
          return;
      }
    });
  };

  const handleOpenPopup = (dealId) => {
    setRestaurantDealId(dealId);
    dispatch(editDealModalOpen());
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Layout>
      <div className="home-content">
        <div className="dealbodyTopsec">
          <h2 className="titleWidth">Manage Your Deals</h2>
          <div className="iconSce">
            <i
              onClick={() => dispatch(addDealModalOpen())}
              className="bx bx-plus"
            ></i>
          </div>
        </div>

        <div style={{ position: "relative" }} className="tableSec">
          {dealData?.isLoading && (
            <div className="tableLoader">
              <div className="spinnerLoad">
                <Loading></Loading>
              </div>
            </div>
          )}

          {isDataLoaded && (
            <div>
              {(dealData?.deals?.currentRestaurantDeals?.length > 0 ||
                dealData?.deals?.expiredRestaurantDeals?.length > 0) && (
                <div className="repeatSec">
                  {dealData?.deals?.currentRestaurantDeals?.length > 0 && (
                    <React.Fragment>
                      <h3>Current Deals</h3>

                      <div className="flexRow">
                        {dealData?.deals?.currentRestaurantDeals?.map(
                          (deal) => (
                            <div
                              className="boxCol"
                              key={deal?.restaurantDealId}
                            >
                              <div className="flexRow">
                                <div className="flexleftCol">
                                  <p>{deal?.restaurantDealTitle}</p>
                                  <p className="greyText">
                                    Start: {formatDate(deal?.startDate)} | End:{" "}
                                    {deal?.endDate
                                      ? formatDate(deal?.endDate)
                                      : "Not Set"}
                                  </p>
                                  {deal?.foodDeals?.length > 0 && (
                                    <p className="greyText">
                                      {renderFoodDeals(deal.foodDeals)}
                                    </p>
                                  )}
                                  <p>
                                    Status:{" "}
                                    <span
                                      style={{
                                        display: "inline-block",
                                        padding: "5px 10px",
                                        borderRadius: "12px",
                                        backgroundColor: deal?.isActive
                                          ? "green"
                                          : "red",
                                        color: "white",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {deal?.isActive
                                        ? "Active"
                                        : "Deactivated"}
                                    </span>
                                  </p>
                                </div>
                                <div className="flexrightCol">
                                  <p className="usercountText">
                                    <span>{deal?.claimCount}</span> Uses
                                  </p>
                                  <div className="dropdownSec">
                                    <i className="bx bx-dots-vertical-rounded"></i>
                                    <ul className="dropdown">
                                      <li
                                        onClick={() =>
                                          handleDeactivateDeal(
                                            deal?.restaurantDealId,
                                            deal?.isActive
                                          )
                                        }
                                      >
                                        {deal?.isActive
                                          ? "Deactivate"
                                          : "Activate"}
                                      </li>
                                      <li
                                        onClick={() =>
                                          handleOpenPopup(
                                            deal?.restaurantDealId
                                          )
                                        }
                                      >
                                        Edit
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </React.Fragment>
                  )}
                  {dealData?.deals?.expiredRestaurantDeals?.length > 0 && (
                    <React.Fragment>
                      <h3>Expired Deals</h3>
                      <div className="flexRow">
                        {dealData?.deals?.expiredRestaurantDeals?.map(
                          (deal) => (
                            <div
                              className="boxCol"
                              key={deal?.restaurantDealId}
                            >
                              <div className="flexRow">
                                <div className="flexleftCol">
                                  <p>{deal?.restaurantDealTitle}</p>
                                  <p className="greyText">
                                    Start: {formatDate(deal?.startDate)} | End:{" "}
                                    {deal?.endDate
                                      ? formatDate(deal?.endDate)
                                      : "Not Set"}
                                  </p>
                                  {deal?.foodDeals?.length > 0 && (
                                    <p className="greyText">
                                      {renderFoodDeals(deal.foodDeals)}
                                    </p>
                                  )}
                                </div>
                                <div className="flexrightCol">
                                  <p className="usercountText">
                                    <span>{deal?.claimCount}</span> Uses
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}
              {dealData?.deals?.currentRestaurantDeals?.length === 0 &&
                dealData?.deals?.expiredRestaurantDeals?.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "17px",
                      padding: "50px 0 0",
                    }}
                  >
                    No Data Found
                  </div>
                )}
            </div>
          )}
        </div>
      </div>

      {isAddDealPopupOpen && (
        <AddDealPopup
          onClose={() => dispatch(addDealModalHide())}
          addresses={addresses}
          restaurantId={restaurantId}
        />
      )}
      {isEditDealPopupOpen && (
        <EditDealPopup
          onClose={() => dispatch(editDealModalHide())}
          restaurantDealId={restaurantDealId}
          pageNum={pageNum}
          pageSize={pageSize}
        />
      )}
    </Layout>
  );
};

export default Deal;
